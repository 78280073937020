@import "../../static/scss/variables.scss";

.MuiInputBase-input {
  padding: 10px !important;
  color: black !important;
}

form > div > label {
  position: absolute !important;
  top: -8px !important;
  margin: 0 !important;
  left: -3px !important;
  color: $grey !important;
  font-size: 13px !important;
}

.Mui-focused {
  top: 0 !important;
  color: $primary !important;
  left: 0.5px !important;
}
