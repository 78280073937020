@import "../../static/scss/variables.scss";
.react-table {
  max-width: 100% !important;
  background-color: $white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  margin-top: -96px;
  position: fixed;
  left: 42px;
  right: 19px;
  top: 200px;
  bottom: 55px;
  overflow-y: hidden;
}

.rt-table {
  // border: 1px solid blue;
  margin-bottom: 70px;
}

.ant-input:focus {
  outline: none !important;
}

.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: none !important;
}

.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: none !important;
  border-right-width: 1px !important;
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ant-calendar-today .ant-calendar-date {
  color: $primary !important;
  font-weight: bold;
  border-color: $primary !important;
}

.ReactTable .rt-thead.-header {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  border: none;
  margin-top: -20px;
}

.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  color: #fff !important;
  background: $primary-gradiant !important;
  box-shadow: $primary-shadow;
  border: 1px solid transparent;
}
.ant-tag-blue {
  color: $white;
  background: $primary;
  border-color: $primary;
}

.rt-th {
  border: none !important;
  margin: 15px 0px 7px 0px;
  font-size: 0.65rem;
  padding: 7px 15px !important;
  font-weight: 500;
}

.rt-td {
  border: none !important;

  padding: 7px 15px !important;
  margin: 8px 0px;
  font-size: 0.65em;
  font-weight: 400;
  text-align: center !important;
}

.ReactTable .-pagination {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  border: none;
}

.ReactTable {
  border: none !important;
  padding: 0rem 1rem 1rem 1rem;
  // border: 1px solid red !important;
  height: 100% !important;
}

.ReactTable {
  input {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 0px !important;
    font-size: 0.75rem !important;
    transition: all 0.5s;
  }
  input:focus {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    border-radius: 0px !important;
    font-size: 0.75rem !important;

    border-bottom: 1px solid $primary;
  }
  .rt-noData {
    max-height: calc(100vh - 15rem);
    font-size: 0.75rem !important;
    font-weight: 600;
  }
}

.ReactTable .-pagination {
  border-top: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 34rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.filter-div {
  display: flex;
  justify-content: end;
}
.time-range-div {
  margin-left: auto;
  cursor: pointer;
}

.filter-lable {
  background: $primary-gradiant !important;
  box-shadow: $primary-shadow;
  padding: 10px !important;
  border-radius: 100%;
}
.filter-lable svg {
  fill: $icon-color;
  height: 1.3rem !important;
}

.react-table {
  max-width: 100% !important;
}

.-pagination {
  justify-content: flex-end !important;
}

.-previous {
  flex: inherit !important;
}

.-center {
  flex: inherit !important;
}
.-next {
  flex: inherit !important;
}

.-pageInfo {
  font-size: 0.8rem !important;
}

.select-wrap select {
  font-size: 0.8rem !important;
}

.-next .-btn {
  font-size: 0.8rem !important;
  color: white !important;
  background: $primary-gradiant !important;
  box-shadow: $primary-shadow !important;
}

.-previous .-btn {
  font-size: 0.8rem !important;
  color: white !important;
  background: $primary-gradiant !important;
  box-shadow: $primary-shadow !important;
}

.pagination-bottom {
  margin-top: 20px;
}

.table-lable-div {
  display: flex;
  margin-left: 80px;
  margin-bottom: 10px;
}

.table-icon svg {
  fill: $icon-color;
}
.table-icon {
  background: linear-gradient(60deg, $primary, $primary) !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px #8181ec;
  padding: 1.2rem 1.15rem;
  margin-top: -72px;
  border-radius: 0.3rem;
  position: fixed;
  top: 150px;
  left: 65px;
}

.table-lable {
  margin-top: 13px;
  margin-left: 15px;
  // font-weight: 300;
  font-size: 1rem;
  letter-spacing: 0.03rem;
  white-space: nowrap;
}
.table-filters {
  width: 100%;

  margin: 5px 20px;
}

.date-filter-lable {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  margin-left: 10px;
  width: 14rem !important;
}

.date-filter-lable input {
  font-size: 0.8rem;
}
