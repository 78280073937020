.selectWrapperrr{
  position: fixed;
  top: 25px;
  left: 40px;
  right: 19px;
  z-index: 1;
  display: flex;
  justify-content: flex-end ;
}

// .selectWrapper > div {
//   margin-left: 10px;
//   width: 200px;
// }

.paginationWrapper {
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.city {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px !important;
  }
 
.wrapper1{
  width: 200px!important;
  z-index: 1;
}
.wrapper2{
  width: 200px!important;
  z-index: 1;
  margin-left: 10px;

}
.wrapper3{
  margin-left: 10px;
  width: 200px!important;
  z-index: 1;
}
.main_wrapper{
  position: fixed;
  top: 85px;
  left: 240px;
  right: 19px;
  margin-left: 10px;
  display: flex;
}
  // .wrapper > div {
  //   margin-left: 10px;
  //   width: 200px;
  // }

