@import "../../static/scss/variables.scss";
.dashbord-lable {
  width: 100%;
  padding: 1.5rem;
  color: $primary;
  background-color: $white;
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 1rem;
}
