@import "../../static/scss/variables.scss";

.container_class {
  top: 0px;
  position: fixed;
  right: 0px;
  right: 0px;
  z-index: 2000;
}

.render_dashboard_menu {
  background-color: whitesmoke;
  color: black;
  font-size: 12px;
  font-weight: 500;

  transition: box-shadow 100ms linear;
}

.render_dashboard_menu:hover {
  box-shadow: 0px 0px 1px 2px #0000002b;
}

:global(.set_width_class > .MuiListItemIcon-root > .MuiListItemIcon-root),
:global(.set_width_class > .MuiListItemIcon-root) {
  min-width: 30px !important;
}

a:hover {
  text-decoration: none !important;
  color: #000000 !important;
}


.MuiListItemIcon-root {
  min-width: 30px !important;
}