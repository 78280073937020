@import "./base.scss";

body {
  // font-family: $font-family;
  font-family: "Poppins", sans-serif !important;
  background: #f8f8f8 !important;
  overflow: hidden;
}

.App {
  p {
    margin: 5px;
    font-size: 16px;
  }
}
.headerMainTitle {
  font-size: 20px;
}

.mainBodyPart {
  background: $body-bg;
  height: 80vh;
}
